import React, { useState, useEffect, useLayoutEffect, forwardRef, useRef, useCallback } from "react";
import { Timestamp } from 'firebase/firestore';

import { useNavigate, useParams, Navigate, Link, useLocation } from 'react-router-dom';
import { getAuth, deleteUser } from "firebase/auth";
import { useAuthContext } from '../../../AuthContext';
import IfProxy from '../../../IfProxy';
import IfProxyTool from '../../../IfProxyTool';


import HeadFooter from '../../HeadFooter';

// ReactGA Analytics
import ReactGA from "react-ga4";

// Material UI
import { makeStyles } from '@mui/styles';

// スピナーアニメーション
import { Dna } from 'react-loader-spinner'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
// アニメーション
import { motion, useAnimation } from "motion/react"

import Typography from '@mui/material/Typography';

//graph
import StopWatch from "../../../common/graphs/StopWatch";
import { Scale } from "victory";
import RecordParcentGraph from "../../../common/graphs/RecordParcentGraph";
import RecordParcentBarGraph from "../../../common/graphs/RecordParcentBarGraph";

import DisplayAd from "../../../common/adsence/DisplayAd"

// ショートかっと
import hotkeys from 'hotkeys-js';

import { domToPng } from 'modern-screenshot'



const Result = (props) => {
    const auth = getAuth();
    const navigate = useNavigate();
    const { user } = useAuthContext();
    const classes = useStyles();
    const params = useParams();
    const location = useLocation();
    const ifProxy = new IfProxy();
    const ifProxyTool = new IfProxyTool();

    // 表示値
    const [resultTotalTime, setResultTotalTime] = useState(false);
    // 個別のステップと後の実績値
    const [resultTimes, setResultTimes] = useState(false);


    // アニメーション
    const useAnimationControll = useAnimation()


    const [loading, setLoading] = useState(true);
    // 日付のデータ型変換のため
    const [recordDataParam2, setRecordDataParam2] = useState(false);

    console.log("TESTDATA======-")
    console.log(location.state)
    const userData = location.state.userData
    const recordDataParam = location.state.recordDataParam
    const recordConfig = location.state.setting //configの名前


    let first = true;

    useLayoutEffect((props) => {


        // 【useEffectの２重処理について】
        // https://zenn.dev/takeharu/scraps/d14cf9d4239ec4
        // useEffectは２回描画される
        // mount -> unmount -> mount
        // 処理がこのようにダブルのは開発環境だけ
        if (first) {
            setLoading(true)

            // ReactGA Analytics ---
            ReactGA.initialize("G-2ZCXE08H2S");
            ReactGA.send({
                hitType: "pageview",
                // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
                page: location.pathname + location.search,
            });
            // ------
            loadData();



        }

        //    console.log(first ? "１回目である" : "２回目である")
        //    console.log("回数評価処理は以下")
        //    console.log("開発環境２重処理防止のため、１回目、２回目判定フラグ")
        //    console.log(first)
        first = false;
        //    console.log("２重登録判定フラグ、更新後")
        //    console.log(first)

        return () => {
            //      console.log("UNMOUNT 開発環境２重処理防止のため、１回目、２回目判定フラグ")
            //      console.log(first)
        }

    }, []);



    const loadData = async () => {

        let recordConfig = []
        if (user) {
        }

        const startDt = recordDataParam.step0Time;
        const resultDt = recordDataParam.completeTime

        const difMinMs = (resultDt?.getTime() - 0) - (startDt?.getTime() - 0)
        const difMin = difMinMs / (1000)
        setResultTotalTime(difMin / 60)


        // 日付データをタイムスタンプ型に変換
        let recordDataParam2 = recordDataParam;
        console.log("変換まえ")

        console.log(recordDataParam)

        console.log(recordDataParam.step0Time)
        recordDataParam2.step0Time = recordDataParam.step0Time && Timestamp.fromDate(recordDataParam.step0Time)
        console.log(recordDataParam2.step0Time)

        recordDataParam2.step1Time = recordDataParam.step1Time && Timestamp.fromDate(recordDataParam.step1Time)
        recordDataParam2.step2Time = recordDataParam.step2Time && Timestamp.fromDate(recordDataParam.step2Time)

        recordDataParam2.step3Time = recordDataParam.step3Time && Timestamp.fromDate(recordDataParam.step3Time)
        recordDataParam2.step4Time = recordDataParam.step4Time && Timestamp.fromDate(recordDataParam.step4Time)
        recordDataParam2.step5Time = recordDataParam.step5Time && Timestamp.fromDate(recordDataParam.step5Time)
        recordDataParam2.step6Time = recordDataParam.step6Time && Timestamp.fromDate(recordDataParam.step6Time)
        recordDataParam2.step7Time = recordDataParam.step7Time && Timestamp.fromDate(recordDataParam.step7Time)
        recordDataParam2.step8Time = recordDataParam.step8Time && Timestamp.fromDate(recordDataParam.step8Time)
        recordDataParam2.step9Time = recordDataParam.step9Time && Timestamp.fromDate(recordDataParam.step9Time)
        recordDataParam2.step10Time = recordDataParam.step10Time && Timestamp.fromDate(recordDataParam.step10Time)

        recordDataParam2.completeTime = recordDataParam.completeTime && Timestamp.fromDate(recordDataParam.completeTime)
        setRecordDataParam2(recordDataParam2)
        console.log("変換後")
        console.log(recordDataParam2)


        // 工程ごとの実績時間

        let resultTimes = {
            step1Time: getDiff(recordDataParam.step1Time, recordDataParam.step0Time),
            step2Time: getDiff(recordDataParam.step2Time, recordDataParam.step1Time),
            step3Time: getDiff(recordDataParam.step3Time, recordDataParam.step2Time),
            step4Time: getDiff(recordDataParam.step4Time, recordDataParam.step3Time),
            step5Time: getDiff(recordDataParam.step5Time, recordDataParam.step4Time),
            step6Time: getDiff(recordDataParam.step6Time, recordDataParam.step5Time),
            step7Time: getDiff(recordDataParam.step7Time, recordDataParam.step6Time),
            step8Time: getDiff(recordDataParam.step8Time, recordDataParam.step7Time),
            step9Time: getDiff(recordDataParam.step9Time, recordDataParam.step8Time),
            step10Time: getDiff(recordDataParam.step10Time, recordDataParam.step9Time)
        }

        setResultTimes(resultTimes)


        setLoading(false)

    }


    const getDiff = (resultDt, startDt) => {
        const difMin = (resultDt - 0) - (startDt - 0)
        return Math.floor(difMin)
    }
    /**
* nullは0にする。文字は数値にする
* @param {*} param 
* @returns 
*/
    const psi = ((param) => {
        return param - 0
    })

    //目標合計所要分 
    const targetMin_result = psi(recordConfig.step1Time) + psi(recordConfig.step2Time) + psi(recordConfig.step3Time) + psi(recordConfig.step4Time) + psi(recordConfig.step5Time) + psi(recordConfig.step6Time) + psi(recordConfig.step7Time) + psi(recordConfig.step8Time) + psi(recordConfig.step9Time) + psi(recordConfig.step10Time)

    const resultDiff = (targetMin_result, resultTotalTime) => {


        if (resultTotalTime - targetMin_result < 1) {
            // 目標を引いたらマイナス(早い）
            return (
                <>
                    <Typography color="green">余裕時間 {Math.floor(resultTotalTime - targetMin_result)} 分</Typography>
                </>
            )
        } else {
            // 目標を引いたら目標より時間が必要（遅れ)
            return (
                <>
                    <Typography color="red">超過時間 {Math.floor(resultTotalTime - targetMin_result)} 分</Typography>

                </>
            )
        }

    }


    const createDiary = async () => {
        const recordData = await ifProxyTool.addRecord(recordDataParam, recordDataParam);

        const diaryData = {
            toUid: userData.uid,
            toUser: userData.displayName,
            toIcon: userData.icon,
            privateView: false,
            diary: "",
            memoForNext: "",
            imageUrl: "",
            videoUrl: "",
            youtubeUrl: "",
            tag: [],
            tagString: [],
            onedroTag: [],
            onedroTagString: [],
            record: recordData,
            recordConfig: recordConfig,
        }
        const result = await ifProxyTool.addDiary(diaryData, diaryData);
        navigate('/Diary/' + result.id)

    }
    /**
     * 予定実績一覧表
     * @param {*} target 目標秒
     * @param {*} time 実績秒
     * @returns 
     */
    const resultDetail = (stapName, target, time) => {


        const timeMin = Math.floor(time / 60)
        if (Math.floor(time) < 1) {
            return (
                <>
                    記録なし(タイムが1秒以下)
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                </>
            )
        } else {
            return (<>
                <b>{stapName}</b><br></br>
                目標:{target} | 実績:{timeMin} | {resultDiff(target, timeMin)}
                <br></br>
            </>)
        }

    }
    /**
     * 予定実績一覧表　カウンター用　（予定をぬく）
     * @param {*} time 実績秒
     * @returns 
     */
    const resultDetailForCounter = (stapName, time) => {


        const timeMin = Math.floor(time / 60)
        if (Math.floor(time) < 1) {
            return (
                <>
                    記録なし<br></br>
                    (タイムが1秒以下)
                    <br></br>
                </>
            )
        } else {
            return (<>
                <b>{stapName}</b><br></br>
                タイム:{timeMin} 分
                <br></br>
            </>)
        }

    }
    /**
 * 投稿用
 * 秒形式を 00:00 or - に変換
 * @param {*} time 実績秒
 * @returns 文字列
 */
    const fomartTime = (stepName, time) => {
        const timeMin = Math.floor(time / 60);
        const timeSec = time % 60;
        if (stepName == '') return "";

        if (Math.floor(time) < 1) {
            return '-'
        } else {
            return `${stepName}: ${timeMin}:${timeSec}-> `
        }

    }

    if (loading) {

    } else {

        if (recordConfig.configName === "COUNTER") {
            // カウンターモードの場合は、目標タイムがないため、recordConfigを読み取らない
            return (<motion.div animate={{
                y: [-1000, 100, 0],
                scale: 1, opacity: 1, transition: { duration: 0.5, type: "tween" }
            }} >
                <Box className={classes.centerBox} id="resultpage">
                    <h1>タイム: {Math.floor(resultTotalTime)}分</h1>

                    <Grid container spacing={2} width="95%">
                        <Grid item xs={12} md={6}>
                            {recordDataParam2 && <RecordParcentGraph recordData={recordDataParam2} />}
                        </Grid>

                        <Grid item xs={12} md={6}>
                            {recordDataParam2 && <RecordParcentBarGraph recordData={recordDataParam2} />}

                        </Grid>
                        {/* 結果一覧 */}
                        <Grid item xs={12} md={6} >
                            <motion.div animate={{
                                scale: [1.5, 1], opacity: [0, 1], transition: { delay: 1, duration: 1, type: "spring" }
                            }} >
                                1){resultDetailForCounter(recordConfig.step1Name, resultTimes.step1Time)}
                            </motion.div>
                            <motion.div animate={{
                                scale: [1.5, 1], opacity: [0, 1], transition: { delay: 1.1, duration: 1, type: "spring" }
                            }} > 2){resultDetailForCounter(recordConfig.step2Name, resultTimes.step2Time)}</motion.div>
                            <motion.div animate={{
                                scale: [1.5, 1], opacity: [0, 1], transition: { delay: 1.2, duration: 1, type: "spring" }
                            }} > 3){resultDetailForCounter(recordConfig.step3Name, resultTimes.step3Time)}</motion.div>

                            <motion.div animate={{
                                scale: [1.5, 1], opacity: [0, 1], transition: { delay: 1.3, duration: 1, type: "spring" }
                            }} >4){resultDetailForCounter(recordConfig.step4Name, resultTimes.step4Time)}</motion.div>

                            <motion.div animate={{
                                scale: [1.5, 1], opacity: [0, 1], transition: { delay: 1.4, duration: 1, type: "spring" }
                            }} > 5){resultDetailForCounter(recordConfig.step5Name, resultTimes.step5Time)}</motion.div>


                        </Grid>
                        <Grid item xs={12} md={6}>
                            <motion.div animate={{
                                scale: [1.5, 1], opacity: [0, 1], transition: { delay: 2, duration: 1, type: "spring" }
                            }} >
                                6){resultDetailForCounter(recordConfig.step6Name, resultTimes.step6Time)}
                                7){resultDetailForCounter(recordConfig.step7Name, resultTimes.step7Time)}
                                8){resultDetailForCounter(recordConfig.step8Name, resultTimes.step8Time)}
                                9){resultDetailForCounter(recordConfig.step9Name, resultTimes.step9Time)}
                                10){resultDetailForCounter(recordConfig.step10Name, resultTimes.step10Time)}
                            </motion.div>
                        </Grid>


                    </Grid>



                    <motion.div animate={{
                        scale: [1.5, 1], opacity: [0, 1], transition: { delay: 2.5, duration: 1, type: "spring" }
                    }} >
                        作業中に残したメモ
                        <ul>
                            {recordDataParam.onedroNote.map(v => <li> ({v.stepName}) : {v.text}</li>)}
                        </ul>
                    </motion.div>
                    <motion.div animate={{
                        scale: [1.5, 1], opacity: [0, 1], transition: { delay: 3.1, duration: 1, type: "spring" }
                    }} >
                        <Button
                            sx={{ height: "100px", width: "600px" }}
                            disabled={!user}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                createDiary();
                            }}>
                            保存 <br></br>{!user ? "【未ログインの場合保存できません。】" : ""}
                        </Button>
                    </motion.div>
                    <motion.div animate={{
                        scale: [1.5, 1], opacity: [0, 1], transition: { delay: 3.2, duration: 1, type: "spring" }
                    }} >
                        <Button
                            sx={{ height: "100px", width: "600px" }}
                            variant="contained"
                            color="warning"
                            onClick={() => {
                                domToPng(document.querySelector('#resultpage')).then(dataUrl => {
                                    const link = document.createElement('a')
                                    link.download = 'screenshot.png'
                                    link.href = dataUrl
                                    link.click()
                                })
                            }}
                        >
                            結果のスクリーンショットを取得する
                        </Button>
                    </motion.div>
                    <motion.div animate={{
                        scale: [1.5, 1], opacity: [0, 1], transition: { delay: 3.3, duration: 1, type: "spring" }
                    }} >
                        <Button
                            sx={{ height: "100px", width: "600px" }}
                            variant="contained"
                            color="warning"
                            onClick={() => {
                                //window.open(`http://twitter.com/share?&text=今日のタイムログ:${Math.floor(resultTotalTime)}分 ${fomartTime(recordConfig.step1Name, resultTimes.step1Time)}${fomartTime(recordConfig.step2Name, resultTimes.step2Time)}${fomartTime(recordConfig.step3Name, resultTimes.step3Time)}${fomartTime(recordConfig.step4Name, resultTimes.step4Time)}${fomartTime(recordConfig.step5Name, resultTimes.step5Time)}${fomartTime(recordConfig.step6Name, resultTimes.step6Time)}&hashtags=ワンフロ`)
                                window.open(`http://x.com/intent/post?&text=今日のタイムログ:${Math.floor(resultTotalTime)}分 ${fomartTime(recordConfig.step1Name, resultTimes.step1Time)}${fomartTime(recordConfig.step2Name, resultTimes.step2Time)}${fomartTime(recordConfig.step3Name, resultTimes.step3Time)}${fomartTime(recordConfig.step4Name, resultTimes.step4Time)}${fomartTime(recordConfig.step5Name, resultTimes.step5Time)}${fomartTime(recordConfig.step6Name, resultTimes.step6Time)}&hashtags=ワンフロ`)

                            }}

                        >
                            ログをXへ投稿
                        </Button>
                    </motion.div>
                    <motion.div animate={{
                        scale: [1.5, 1], opacity: [0, 1], transition: { delay: 3.4, duration: 1, type: "spring" }
                    }} >
                        <Button
                            sx={{ height: "100px", width: "600px" }}
                            disabled={!user}
                            variant="outlined"
                            color="error"
                            onClick={() => {
                                navigate('/v2/SetupTimer');
                            }}>
                            クリアして戻る
                        </Button>
                    </motion.div>

                </Box >

            </motion.div >)
        } else {




            return (<motion.div
                animate={{
                    y: [-1000, 100, 0],
                    scale: 1, opacity: 1, transition: { duration: 0.2, ease: "easeInOut" }
                }}>
                <Box className={classes.centerBox} id="resultpage">
                    <h1>タイム: {Math.floor(resultTotalTime)}分</h1>
                    <p>目標タイム: {Math.floor(targetMin_result)}分(スケジュールの種類: {recordConfig.configName})</p>
                    <h3>進行結果 {resultDiff(targetMin_result, resultTotalTime)}</h3>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            {recordDataParam2 && <RecordParcentGraph recordData={recordDataParam2} />}
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            {recordDataParam2 && <RecordParcentBarGraph recordData={recordDataParam2} />}

                        </Grid>
                        {/* 余日一覧表 */}
                        <Grid item xs={6} sm={4}>
                            <motion.div animate={{
                                scale: [1.5, 1], opacity: [0, 1], transition: { delay: 1, duration: 1, type: "spring" }
                            }} >
                                1){resultDetail(recordConfig.step1Name, recordConfig.step1Time, resultTimes.step1Time)}</motion.div>
                            <motion.div animate={{
                                scale: [1.5, 1], opacity: [0, 1], transition: { delay: 1.1, duration: 1, type: "spring" }
                            }} >2){resultDetail(recordConfig.step2Name, recordConfig.step2Time, resultTimes.step2Time)}</motion.div>

                            <motion.div animate={{
                                scale: [1.5, 1], opacity: [0, 1], transition: { delay: 1.2, duration: 1, type: "spring" }
                            }} >3){resultDetail(recordConfig.step3Name, recordConfig.step3Time, resultTimes.step3Time)}</motion.div>
                            <motion.div animate={{
                                scale: [1.5, 1], opacity: [0, 1], transition: { delay: 1.3, duration: 1, type: "spring" }
                            }} >4){resultDetail(recordConfig.step4Name, recordConfig.step4Time, resultTimes.step4Time)}</motion.div>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <motion.div animate={{
                                scale: [1.5, 1], opacity: [0, 1], transition: { delay: 1.5, duration: 1, type: "spring" }
                            }} >
                                5){resultDetail(recordConfig.step5Name, recordConfig.step5Time, resultTimes.step5Time)}
                                6){resultDetail(recordConfig.step6Name, recordConfig.step6Time, resultTimes.step6Time)}

                                7){resultDetail(recordConfig.step7Name, recordConfig.step7Time, resultTimes.step7Time)}
                                8){resultDetail(recordConfig.step8Name, recordConfig.step8Time, resultTimes.step8Time)}</motion.div>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <motion.div animate={{
                                scale: [1.5, 1], opacity: [0, 1], transition: { delay: 1.6, duration: 1, type: "spring" }
                            }} >
                                9){resultDetail(recordConfig.step9Name, recordConfig.step9Time, resultTimes.step9Time)}
                                10){resultDetail(recordConfig.step10Name, recordConfig.step10Time, resultTimes.step10Time)}</motion.div>
                        </Grid>

                    </Grid>



                    <motion.div animate={{
                        scale: [1.5, 1], opacity: [0, 1], transition: { delay: 2, duration: 1, type: "spring" }
                    }} >
                        作業中に残したメモ
                        <ul>
                            {recordDataParam.onedroNote.map(v => <li> ({v.stepName}) : {v.text}</li>)}
                        </ul>
                    </motion.div>
                    <motion.div animate={{
                        scale: [1.5, 1], opacity: [0, 1], transition: { delay: 3, duration: 1, type: "spring" }
                    }} >
                        <Button
                            sx={{ height: "100px", width: "80%" }}
                            disabled={!user}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                createDiary();
                            }}>
                            保存 <br></br>{!user ? "【未ログインの場合保存できません。】" : ""}
                        </Button>
                    </motion.div>
                    <motion.div animate={{
                        scale: [1.5, 1], opacity: [0, 1], transition: { delay: 3.1, duration: 1, type: "spring" }
                    }} >
                        <Button
                            sx={{ height: "100px", width: "80%" }}
                            variant="contained"
                            color="warning"
                            onClick={() => {
                                domToPng(document.querySelector('#resultpage')).then(dataUrl => {
                                    const link = document.createElement('a')
                                    link.download = 'screenshot.png'
                                    link.href = dataUrl
                                    link.click()
                                })
                            }}
                        >
                            結果のスクリーンショットを取得する
                        </Button>
                    </motion.div>
                    <motion.div animate={{
                        scale: [1.5, 1], opacity: [0, 1], transition: { delay: 3.2, duration: 1, type: "spring" }
                    }} >
                        <Button
                            sx={{ height: "100px", width: "80%" }}
                            disabled={!user}
                            variant="outlined"
                            color="error"
                            onClick={() => {
                                navigate('/v2/SetupTimer');
                            }}>
                            クリアして戻る
                        </Button>
                    </motion.div>

                </Box>

            </motion.div >)
        }
    }
}
export default Result;



const style = {
    scoreLabel: {
        fontSize: 25,

    },
}

const useStyles = makeStyles((theme) => ({

    // 全体レイアウト
    contentWrapper: {
        marginTop: 20,
        flexDirection: 'column',
        alignItems: 'center',
    },
    mainBox: {
        maxWidth: "1000px",
        margin: "0 auto",

    },

    // 個別コンテンツ　中央揃えで縦に内容を並べる
    centerBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'white'
    },// 個別コンテンツ　中央揃えで横に内容を並べる
    centerFlatBox: {
        display: 'flex',
        flexDirection: 'row',
        margin: 10,
        alignItems: 'center',
    },
    // 個別コンテンツ　左揃えで縦に内容を並べる
    leftCenterBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
    },
    // 個別コンテンツ　左揃えで縦に内容を並べる
    leftRowBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'left',
    },
    // 個別コンテンツ　横並びに内容を並べる
    leftBox: {
        display: 'flex',
        flexDirection: 'row',
        margin: "0 auto",
    },

}));