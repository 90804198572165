import React, { useState, useEffect, useLayoutEffect, forwardRef, useRef, useCallback } from "react";
import { useNavigate, useParams, Navigate, Link, useLocation } from 'react-router-dom';
import { getAuth, deleteUser } from "firebase/auth";
import { useAuthContext } from '../../../AuthContext';
import IfProxy from '../../../IfProxy';
import IfProxyTool from '../../../IfProxyTool';


import HeadFooter from '../../HeadFooter';

// ReactGA Analytics
import ReactGA from "react-ga4";

// Material UI
import { makeStyles } from '@mui/styles';

// スピナーアニメーション
import { Dna } from 'react-loader-spinner'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';

// アニメーション
import { motion, useAnimation } from "motion/react"

//graph
import StopWatchForCounter from "../../../common/graphs/StopWatchForCounter";
import { Scale } from "victory";

import DisplayAd from "../../../common/adsence/DisplayAd"


// ショートかっと
import hotkeys from 'hotkeys-js';
const OnedroTimerCountup = (props) => {
    const auth = getAuth();
    const navigate = useNavigate();
    const { user } = useAuthContext();
    const classes = useStyles();
    const params = useParams();
    const location = useLocation();
    const ifProxy = new IfProxy();
    const ifProxyTool = new IfProxyTool();

    const useAnimationControll = useAnimation()
    const useAnimationControll_changeMode = useAnimation()

    const [started, setStarted] = useState(false);
    const [completed, setCompleted] = useState(true);



    const [loading, setLoading] = useState(true);
    // RecordConfig
    const [recordConfig, setRecordConfig] = useState(false); //表示している１つのコンフィグ 作業時間管理では、Nameだけユーザが設定した名前を、次へ、ごとに登録する
    const [stepNameInput, setStepNameInput] = useState(""); // 時間を登録するステップの名前
    // ワンドロ中のメモを持つ配列 Diaryに登録される
    const [aOnedroNote, setAOnedroNote] = useState([]); // ワンドロノート フォームの文字を持つ
    const [onedroNote, setOnedroNote] = useState([]); // ワンドロノート


    // ショートカット
    const shortCat = () => {
        hotkeys('5', function (event, handler) {
            console.log("shortCat======")
            event.preventDefault()
            alert('you pressed F5!')
        });
    }

    const unregisterrHotkeys = () => {
        hotkeys.unbind('5');
    }


    let first = true;

    useLayoutEffect((props) => {

        shortCat();

        // 【useEffectの２重処理について】
        // https://zenn.dev/takeharu/scraps/d14cf9d4239ec4
        // useEffectは２回描画される
        // mount -> unmount -> mount
        // 処理がこのようにダブルのは開発環境だけ
        if (first) {
            setLoading(true)
            // ReactGA Analytics ---
            ReactGA.initialize("G-2ZCXE08H2S");
            ReactGA.send({
                hitType: "pageview",
                // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
                page: location.pathname + location.search,
            });
            ReactGA.event("ワンドロタイマーを利用した");
            // ------
            loadData();
        }

        //    console.log(first ? "１回目である" : "２回目である")
        //    console.log("回数評価処理は以下")
        //    console.log("開発環境２重処理防止のため、１回目、２回目判定フラグ")
        //    console.log(first)
        first = false;
        //    console.log("２重登録判定フラグ、更新後")
        //    console.log(first)

        return () => {
            //      console.log("UNMOUNT 開発環境２重処理防止のため、１回目、２回目判定フラグ")
            //      console.log(first)
            unregisterrHotkeys()
        }

    }, []);

    const intervalRef = useRef(null);
    //const start = useCallback(() => {
    const start = () => {
        if (intervalRef.current !== null) {
            return;
        }
        setStarted(true)
        setCompleted(false)
        setNowTimeDt(new Date())
        setTargets()
        // タイマースタート
        intervalRef.current = setInterval(() => {
            setNowTimeDt(new Date())
        }, 1000);
        useAnimationControll.start({
            x: [200, -20, 0],
            scale: [0.7, 1], opacity: [0, 1], transition: { duration: 0.5, ease: "easeOut" }
        });
    };
    const stop = useCallback(() => {
        if (intervalRef.current === null) {
            return;
        }
        clearInterval(intervalRef.current);
        intervalRef.current = null;
    }, []);

    const loadData = async () => {
        let recordConfigData = []
        if (user) {
        }


        setRecordConfig({
            id: "",
            uid: user?.uid,
            step1Name: "",
            step2Name: "",
            step3Name: "",
            step4Name: "",
            step5Name: "",
            step6Name: "",
            step7Name: "",
            step8Name: "",
            step9Name: "",
            step10Name: "",
            step1Time: 60,
            step2Time: 60,
            step3Time: 60,
            step4Time: 60,
            step5Time: 60,
            step6Time: 60,
            step7Time: 60,
            step8Time: 60,
            step9Time: 60,
            step10Time: 60,
            memoForNext: 60,
            memo: "",
        });
        setLoading(false)
    }

    const toResult = async () => {
        // TODO Contextでよくない？
        let userData;
        if (user) {
            userData = await ifProxy.getUserData(user.uid)
        } else {
            userData = { message: "ログインしていないユーザのためuserDataなし", uid: "NOT_LOGIN", displayName: "未ログインユーザ", icon: "" }
        }

        const recordDataParam = {
            step0Time: startDt,
            step1: recordConfig.step1Name,
            step1Time: resultStepDt1,
            step1FixCount: end1Fixcount,
            step2: recordConfig.step2Name,
            step2Time: resultStepDt2,
            step2FixCount: end2Fixcount,
            step3: recordConfig.step3Name,
            step3Time: resultStepDt3,
            step3FixCount: end3Fixcount,
            step4: recordConfig.step4Name,
            step4Time: resultStepDt4,
            step4FixCount: end4Fixcount,
            step5: recordConfig.step5Name,
            step5Time: resultStepDt5,
            step5FixCount: end5Fixcount,
            step6: recordConfig.step6Name,
            step6Time: resultStepDt6,
            step6FixCount: end6Fixcount,
            step7: recordConfig.step7Name,
            step7Time: resultStepDt7,
            step7FixCount: end7Fixcount,
            step8: recordConfig.step8Name,
            step8Time: resultStepDt8,
            step8FixCount: end8Fixcount,
            step9: recordConfig.step9Name,
            step9Time: resultStepDt9,
            step9FixCount: end9Fixcount,
            step10: recordConfig.step10Name,
            step10Time: resultStepDt10,
            step10FixCount: end10Fixcount,
            onedroNote,
            completeTime: new Date(),
            uid: userData.uid || "NOT_LOGIN",
        }
        // カウンターモードの場合、目標タイム値を持たない。
        // しかし、現在工程名を記録するために利用する
        const setting = {
            step1Name: recordConfig.step1Name,
            step2Name: recordConfig.step2Name,
            step3Name: recordConfig.step3Name,
            step4Name: recordConfig.step4Name,
            step5Name: recordConfig.step5Name,
            step6Name: recordConfig.step6Name,
            step7Name: recordConfig.step7Name,
            step8Name: recordConfig.step8Name,
            step9Name: recordConfig.step9Name,
            step10Name: recordConfig.step10Name,
            configName: "COUNTER"
        }
        navigate('/v2/Result', { state: { userData, recordDataParam, setting } })
    }

    /**
 * nullは0にする。文字は数値にする
 * @param {*} param 
 * @returns 
 */
    const psi = ((param) => {
        return param - 0
    })

    //目標合計所要分 
    const targetMin_result = psi(recordConfig.step1Time) + psi(recordConfig.step2Time) + psi(recordConfig.step3Time) + psi(recordConfig.step4Time) + psi(recordConfig.step5Time) + psi(recordConfig.step6Time) + psi(recordConfig.step7Time) + psi(recordConfig.step8Time) + psi(recordConfig.step9Time) + psi(recordConfig.step10Time)

    // 現在ステップ
    const [stepNo, setStepNo] = useState(); // 現在時刻


    const [nowTimeDt, setNowTimeDt] = useState(); // 現在時刻
    const [nowStepName, setNowStepName] = useState(""); // 現在のステップ名
    const [startDt, setStartDt] = useState(); //開始時刻
    const [stepStartDt, setStepStartDt] = useState(); //直前の工程の完了時刻
    const [nowStepTargetDt, setNowStepTargetDt] = useState(); //現在のステップの目標時間
    const [nowStepTarget, setNowStepTarget] = useState(); //現在のステップの目標分 時間枠

    // 完了時刻
    const [resultStepDt1, setResultStepDt1] = useState(null);
    const [resultStepDt2, setResultStepDt2] = useState(null);
    const [resultStepDt3, setResultStepDt3] = useState(null);
    const [resultStepDt4, setResultStepDt4] = useState(null);
    const [resultStepDt5, setResultStepDt5] = useState(null);
    const [resultStepDt6, setResultStepDt6] = useState(null);
    const [resultStepDt7, setResultStepDt7] = useState(null);
    const [resultStepDt8, setResultStepDt8] = useState(null);
    const [resultStepDt9, setResultStepDt9] = useState(null);
    const [resultStepDt10, setResultStepDt10] = useState(null);
    const [endEstimatedDatetime, setEndEstimatedDatetime] = useState(null);

    // 戻って修正した回数 // はいし。一旦0を全て入れる
    const [end1Fixcount, setEnd1Fixcount] = useState(0);
    const [end2Fixcount, setEnd2Fixcount] = useState(0);
    const [end3Fixcount, setEnd3Fixcount] = useState(0);
    const [end4Fixcount, setEnd4Fixcount] = useState(0);
    const [end5Fixcount, setEnd5Fixcount] = useState(0);
    const [end6Fixcount, setEnd6Fixcount] = useState(0);
    const [end7Fixcount, setEnd7Fixcount] = useState(0);
    const [end8Fixcount, setEnd8Fixcount] = useState(0);
    const [end9Fixcount, setEnd9Fixcount] = useState(0);
    const [end10Fixcount, setEnd10Fixcount] = useState(0);
    /**
     * 　
     */
    const setTargets = () => {
        setStepNo(1)
        let date = new Date()
        setStartDt(date)
        setStepStartDt(date) //初回は開始時刻と同じ
        let dateStepTime = new Date()
        const log = 60
        setNowStepTarget(log)
        setNowStepName("");// 次のステップに行く前に初期化
    }

    const setStateStep = (no, stepTarget) => {
        let date = new Date()
        let dateStepTime = new Date()
        setStepNo(no) //次に入るステップ
        setStepStartDt(date) // 前回ステップの終了時間（このボタンを押した時）９
        setNowStepTargetDt(dateStepTime.setMinutes(dateStepTime.getMinutes() + stepTarget))// 目標分＋スタート時刻
        setNowStepTarget(stepTarget) // ステップの目標分

    }

    /**
     * 
     */
    // TODO １、２が入らないで、3回目がstep1に入り、4回目が二に入る。ズレているのでなおす
    const goNext = () => {
        useAnimationControll.start({
            x: [200, -20, 0],
            scale: [0.7, 1], opacity: [0, 1], transition: { duration: 0.5, ease: "easeOut" }
        });
        // stepNoは直前のステップが入る
        switch (stepNo) {
            case 1: setStateStep(2, recordConfig.step2Time)  //step2に入る時の初期化
                setResultStepDt1(new Date())

                setRecordConfig({
                    ...recordConfig,
                    step1Name: nowStepName
                });
                break;
            case 2: setStateStep(3, recordConfig.step3Time) //step3に入る時の初期化
                setResultStepDt2(new Date())
                setRecordConfig({
                    ...recordConfig,
                    step2Name: nowStepName
                });
                break;
            case 3: setStateStep(4, recordConfig.step4Time) //step4に入る時の初期化
                setResultStepDt3(new Date())
                setRecordConfig({
                    ...recordConfig,
                    step3Name: nowStepName
                });
                break;
            case 4: setStateStep(5, recordConfig.step5Time) //step5に入る時の初期化
                setResultStepDt4(new Date())
                setRecordConfig({
                    ...recordConfig,
                    step4Name: nowStepName
                });
                break;
            case 5: setStateStep(6, recordConfig.step6Time) //step6に入る時の初期化
                setResultStepDt5(new Date())
                setRecordConfig({
                    ...recordConfig,
                    step5Name: nowStepName
                });
                break;
            case 6: setStateStep(7, recordConfig.step7Time) //step7に入る時の初期化
                setResultStepDt6(new Date())
                setRecordConfig({
                    ...recordConfig,
                    step6Name: nowStepName
                });
                break;
            case 7: setStateStep(8, recordConfig.step8Time) //step8に入る時の初期化
                setResultStepDt7(new Date())
                setRecordConfig({
                    ...recordConfig,
                    step7Name: nowStepName
                });
                break;
            case 8: setStateStep(9, recordConfig.step9Time) //step9に入る時の初期化
                setResultStepDt8(new Date())
                setRecordConfig({
                    ...recordConfig,
                    step8Name: nowStepName
                });
                break;
            case 9: setStateStep(10, recordConfig.step10Time) //step10に入る時の初期化
                setResultStepDt9(new Date())
                setRecordConfig({
                    ...recordConfig,
                    step9Name: nowStepName
                });
                break;
            case 10:
                setResultStepDt10(new Date())
                setRecordConfig({
                    ...recordConfig,
                    step10Name: nowStepName
                });
                break;

            default:
                break;

        }
        setNowStepName("");// 次のステップに行く前に初期化






    }/**
     * 
     */
    const conplete = () => {
        let result = window.confirm("すべての工程が完了し、タイマーストップしますか？")
        if (result) {
            setCompleted(true)
            stop()
            setEndEstimatedDatetime(new Date())
        }

    }

    if (loading) {

        return (
            <>
                読み込み中
            </>
        )
    } else {

        return (<motion.div
            initial={{ scale: 1, opacity: 0 }}
            animate={{
                y: [-1000, 100, 0],
                scale: 1, opacity: 1, transition: { duration: 0.2, ease: "easeInOut" }
            }}
        >
            <Box sx={{ margin: '5px' }}>

                {recordConfig.step1Name}  → {recordConfig.step2Name} → {recordConfig.step3Name} → {recordConfig.step4Name} → {recordConfig.step5Name} → {recordConfig.step6Name} → {recordConfig.step7Name}
                → {recordConfig.step8Name} → {recordConfig.step9Name} → {recordConfig.step10Name} → 完了
                {/* ----- タイマーの予定時間選択メニュ ここまで ------ */}
                <h1>現在の工程　{stepNo} : {nowStepName}</h1>
                <Box className={classes.centerFlatBox}>
                    <Button sx={{ backgroundColor: !started ? 'blue' : 'gray', height: '80px' }} fullWidth disabled={started} size="large" onClick={() => start()}><Typography color={!started ? 'white' : 'darkgray'} fontSize={25}>スタート</Typography></Button>
                    <Button sx={{ backgroundColor: !completed ? 'blue' : 'gray', height: '80px' }} fullWidth disabled={completed} onClick={() => goNext()}><Typography color={!completed ? 'white' : 'darkgray'} fontSize={25}>{nowStepName}完了 次へ</Typography></Button>
                    <Button sx={{ backgroundColor: !completed ? 'red' : 'gray', height: '80px' }} fullWidth disabled={completed} onClick={() => conplete()}><Typography color={!completed ? 'white' : 'darkgray'} fontSize={50}>完了</Typography></Button>
                </Box>
                {nowTimeDt && <Box className={classes.centerFlatBox}>
                    {nowStepName == "" && <Typography color="red" fontSize={(30)}>(必須)作業名を入力</Typography>}
                </Box>}
                {(nowTimeDt && nowStepName == "") && <motion.h1
                    initial={{ scale: 1, opacity: 0 }}
                    animate={{
                        x: [1000, -100, 0],
                        scale: 1, opacity: 1, transition: { duration: 1, ease: "easeInOut" }
                    }}
                >まず、する作業を入力 ↓</motion.h1>
                }
                {nowTimeDt && <Box className={classes.centerFlatBox}>
                    <TextField margin="nomal" id="filled-basic" size="midium"
                        fullWidth
                        backgroundColor="red"
                        label="今実施している作業名を設定してください"
                        placeholder="ラフ"
                        onKeyDown={e => {
                            if (e.keyCode === 13) {
                                e.target.blur();
                            }
                        }}
                        onChange={(event) => {
                            setNowStepName(() => event.target.value)
                        }} variant="outlined" type="string" value={nowStepName}

                    />
                </Box>}



                <Grid container spacing={0}>
                    <Grid item xs={12} md={6}>
                        <motion.div
                            animate={useAnimationControll}
                        >
                            {nowTimeDt && <h1>{nowStepName} の作業時間</h1>}
                            {!nowTimeDt && <h2><font color="gray">スタートボタンで計測を開始します</font></h2>}
                            {nowTimeDt && <StopWatchForCounter key="StopWatchForCounter2" maxTime={nowStepTarget} startTime={stepStartDt} nowTime={nowTimeDt} />}
                        </motion.div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <h1>累計タイム</h1>
                        {!nowTimeDt && <h2><font color="gray">スタートボタンで計測を開始します</font></h2>}
                        {nowTimeDt && <StopWatchForCounter key="StopWatchForCounter1" maxTime={targetMin_result} startTime={startDt} nowTime={nowTimeDt} />}
                    </Grid>

                </Grid>
                <hr></hr>

                <Box className={classes.centerBox}>
                    <Typography variant="h5" color="secondary"
                    >ノート 作業中に思いついた改善アイデアをメモ</Typography>
                    <ul>
                        {onedroNote.map(v => <li>対象ステップ ({v.stepName}) : {v.text}</li>)}
                    </ul>

                    <TextField margin="nomal" id="filled-basic" size="small"
                        fullWidth
                        onChange={(event) => {
                            setAOnedroNote(() => event.target.value)
                        }} variant="outlined" type="string" value={aOnedroNote}
                    />
                    <Button
                        variant="outlined"
                        color="secondary"
                        sx={{ height: "50px", width: "80%" }}
                        onClick={() => {
                            setOnedroNote((pre) => {
                                console.log(pre)
                                let list = pre;
                                list.push({ stepNo: stepNo, stepName: nowStepName, text: aOnedroNote })
                                return list
                            })
                            setAOnedroNote("")

                        }}>メモを追加</Button>
                </Box>
                <hr></hr>
                <Box className={classes.centerBox}>
                    <Button
                        sx={{ height: "100px", width: "80%" }}
                        disabled={!endEstimatedDatetime}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            toResult();
                        }}>
                        結果を見る
                    </Button>

                    <Button
                        variant="contained"
                        sx={{ height: "100px", width: "80%" }}
                        color="error"
                        onClick={() => {
                            let result = window.confirm("保存せず終了します")
                            if (result) {
                                setLoading(true)
                                navigate('/v2/SetupTimer')
                            }
                        }}>
                        中断
                    </Button>
                </Box>
            </Box>
        </motion.div>)
    }

}
export default OnedroTimerCountup;



const style = {
    scoreLabel: {
        fontSize: 25,

    },
}

const useStyles = makeStyles((theme) => ({


    // 個別コンテンツ　中央揃えで縦に内容を並べる
    centerBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },// 個別コンテンツ　中央揃えで横に内容を並べる
    centerFlatBox: {
        display: 'flex',
        flexDirection: 'row',
        margin: 10,
        alignItems: 'center',
    },
    // 個別コンテンツ　左揃えで縦に内容を並べる
    leftCenterBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
    },
    // 個別コンテンツ　左揃えで縦に内容を並べる
    leftRowBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'left',
    },
    // 個別コンテンツ　横並びに内容を並べる
    leftBox: {
        display: 'flex',
        flexDirection: 'row',
        margin: "0 auto",
    },

    //サンプルのグラフエリア
    sampleGraphEria: {
        width: 200
    },

    cardList: {
    },
    card: {
    },
    bottomCard: {
        width: 250
    },
}));